import React, {Component} from 'react';
import {FormControl} from "react-bootstrap";
import axios from "axios";

export default class PersonNote extends Component {

    constructor(props){
        super(props);
        this.state = {
            note: props.note,
            id: props.id,
            showButton: false
        }
    }

    setNote = (event) => {
        this.setState({note: event.target.value, showButton: true});
    };

    saveNote = () => {
        axios.post(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem("token") + '&action=SAVE_NOTE', {contact_id: this.state.id, note: this.state.note})
            .then(res => {
                this.setState({showButton: false})
            })
            .catch(error => console.trace(error));
    }

    render() {
        return (
            <div>
                <FormControl
                    {...(this.state.note ? {value: this.state.note} : {})}
                    id="personNote"
                    style={{minHeight: "200px"}}
                    componentClass="textarea"
                    placeholder="Poznámka"
                    onChange={this.setNote}
                />
                <span className={"mt-5 pull-right"}>
                    {this.state.showButton ? (
                        <button className="btn btn-flat btn-icon bordered-btn" onClick={this.saveNote}>
                            <i className="icon-quill2 position-left"/>Uložiť
                        </button>
                    ) : null}
                </span>
            </div>
        );
    }
}