import React, {Component} from 'react';
import {
    Modal,
    FormGroup,
    Button, Row, Col, FormControl
} from 'react-bootstrap';
import * as moment from 'moment';
import * as Datetime from "react-datetime";
import Select from "../Select";
import Switcher from "../Switch";

export default class InterestButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redModal: false,
            greenModal: false,
            orangeModal: false,
            reasons: props.reasons ? props.reasons : [],
            selectedReason: props.reasons[0].id || 1,
            callTime: moment().format("HH:mm"),
            callDate: moment().format("YYYY-MM-DD"),
            gifts: props.gifts,
            displays: props.displays,
            calendars: props.calendars,
            selectedCalendar: props.calendars[0] ? props.calendars[0].id : '1',
            selectedDisplay: props.displays[0] ? props.displays[0].id : '1',
            note: '',
            selected: {}
        };
        this.tmpReasonSelected = props.reasons[0].id || 1;

        console.log({reasons: props.reasons, selected: this.tmpReasonSelected});
        this.tmpCalendarSelected = 1;
        this.tmpDisplaySelected = 1;
        this.tempSelected = {};
    }

    //<editor-fold desc="red button handlers">
    redHandler = () => {
        this.setState({selected: this.tempSelected}, () => {
            this.props.setData("status", 4);
            this.props.setData("nextCallDate", this.state.callDate);
            this.props.setData("nextCallTime", this.state.callTime);
            this.props.setData("calendar", this.state.selectedCalendar.toString());
            this.props.setData("display", this.state.selectedDisplay.toString());
            this.props.setData("note", this.state.note);
            this.props.setData("gifts", this.state.selected);
            this.props.next();
        });
    };

    redHide = () => {
        this.setState({redModal: false});
    };

    handleDateChange = (event) => {
        let newState = "";
        if (event instanceof moment) {
            newState = event.format("YYYY-MM-DD");
        } else {
            newState = event;
        }
        this.setState({callDate: newState})
    };

    handleTimeChange = (event) => {
        let newState = "";
        if (event instanceof moment) {
            newState = event.format("H:mm");
        } else {
            newState = event;
        }
        this.setState({callTime: newState})
    };

    redCancel = () => {
        this.redHide();
    };

    setNote = (event) => {
        this.setState({note: event.target.value});
    };

    setGift = (name, state) => {
        this.tempSelected[name] = state;
    };

    changeCalendar = (selected) => {
        this.tmpCalendarSelected = selected;
        this.setState({selectedCalendar: this.tmpCalendarSelected});
    };

    changeDisplay = (selected) => {
        this.tmpDisplaySelected = selected;
        this.setState({selectedDisplay: this.tmpDisplaySelected});
    };

    redShow = () => {
        this.setState({redModal: true}, () => {
            this.tempSelected = JSON.parse(JSON.stringify(this.state.selected));
        });
    };
    //</editor-fold>

    //<editor-fold desc="orange button handlers">
    orangeHandler = () => {
        this.props.setData("status", 3);
        this.props.setData("nextCallTime", this.state.callTime);
        this.props.setData("nextCallDate", this.state.callDate);
        this.props.setData("note", this.state.note);
        this.orangeHide();
        this.props.next();
    };

    orangeHide = () => {
        this.setState({orangeModal: false});
    };

    orangeCancel = () => {
        this.orangeHide();
    };

    orangeShow = () => {
        this.setState({orangeModal: true});
    };
    //</editor-fold>

    //<editor-fold desc="green button handlers">
    greenHandler = () => {
        if(String(this.state.note).length > 0) {
            this.props.setData("status", 2);
            this.props.setData("reason", this.state.selectedReason);
            this.props.setData("note", this.state.note);
            this.props.next();
        }
    };

    greenHide = () => {
        this.setState({greenModal: false});
    };

    greenCancel = () => {
        this.tmpReasonSelected = this.state.selectedReason;
        this.greenHide();
    };

    greenShow = () => {
        this.tmpReasonSelected = this.state.selectedReason;
        this.setState({greenModal: true});
    };

    changeSelected = (selected) => {
        this.tmpReasonSelected = selected;
        this.setState({selectedReason: this.tmpReasonSelected});
    };

    //</editor-fold> //

    componentWillReceiveProps(props) {
        this.setState({reasons: props.reasons});
    }


    render() {
        return (
            <div>
                <div className="row main-btns">
                    <div className="col-md-4 col-xs-12">
                        <button type="button" className="btn bg-warning-400 btn-sm"
                                onClick={this.redShow.bind(this)}>
                            <div>
                                Záujem
                            </div>
                        </button>

                        <Modal
                            show={this.state.redModal}
                            onHide={this.redHide}
                            bsSize={"large"}
                        >
                            <Modal.Header className="bg-warning-400">
                                <Modal.Title>Záujem</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <div>Druhý telefonát&nbsp;</div>
                                    <Row>
                                        <Col md={6}>
                                            <Datetime name={"datum"} dateFormat={"dddd DD. MMMM YYYY"}
                                                      defaultValue={moment()} timeFormat={false}
                                                      onChange={this.handleDateChange.bind(this)} input={true}/>
                                        </Col>
                                        <Col md={6}>
                                            <Datetime name={"cas"} dateFormat={false}
                                                      defaultValue={moment()} timeFormat={"HH:mm"}
                                                      onChange={this.handleTimeChange.bind(this)} input={true}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl
                                                id="reason"
                                                componentClass="textarea"
                                                value={this.state.note}
                                                placeholder="Poznámka"
                                                onChange={this.setNote}
                                            />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Select
                                                    label={"Typ kalendára"}
                                                    options={this.state.calendars}
                                                    value={this.state.selectedCalendar}
                                                    onChange={this.changeCalendar.bind(this)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Select
                                                    label={"Typ ukážky"}
                                                    options={this.state.displays}
                                                    value={this.state.selectedDisplay}
                                                    onChange={this.changeDisplay.bind(this)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={12}>
                                            Darčeky
                                            <FormGroup>
                                                <Row>
                                                    <Col md={6}>
                                                        {this.state.gifts
                                                            .filter((item, index) => index % 2 === 0)
                                                            .map(item => (
                                                                <Switcher id={item.id + ""} key={item.id}
                                                                          label={item.value}
                                                                          checked={this.state.selected[item.id] === true}
                                                                          position="right" onChange={this.setGift}/>
                                                            ))}
                                                    </Col>
                                                    <Col md={6}>
                                                        {this.state.gifts
                                                            .filter((item, index) => index % 2 === 1)
                                                            .map(item => (
                                                                <Switcher id={item.id + ""} key={item.id}
                                                                          label={item.value}
                                                                          checked={this.state.selected[item.id] === true}
                                                                          position="right" onChange={this.setGift}/>
                                                            ))}
                                                    </Col>
                                                </Row>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.redCancel}>Zrušiť</Button>
                                <Button className="btn bg-warning-400"
                                        onClick={this.redHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>


                    <div className="col-md-4 col-xs-12">
                        <button type="button" className="btn bg-orange btn-sm" onClick={this.orangeShow.bind(this)}>
                            Volať neskôr
                        </button>

                        <Modal
                            show={this.state.orangeModal}
                            onHide={this.orangeHide}
                        >
                            <Modal.Header className="bg-orange">
                                <Modal.Title>Volať neskôr</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <Datetime name={"datum"} dateFormat={"dddd DD. MMMM YYYY"}
                                                      defaultValue={moment()} timeFormat={false}
                                                      onChange={this.handleDateChange.bind(this)} input={true}/>
                                        </Col>
                                        <Col md={6}>
                                            <Datetime name={"cas"} dateFormat={false}
                                                      defaultValue={moment()} timeFormat={"HH:mm"}
                                                      onChange={this.handleTimeChange.bind(this)} input={true}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl
                                                id="reason"
                                                componentClass="textarea"
                                                value={this.state.note}
                                                placeholder="Poznámka"
                                                onChange={this.setNote}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.orangeCancel}>Zrušiť</Button>
                                <Button className="btn bg-orange"
                                        onClick={this.orangeHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>


                    <div className="col-md-4 col-xs-12">
                        <button type="button" className="btn btn-success btn-sm"
                                onClick={this.greenShow.bind(this)}>
                            <div>
                                Nezáujem
                            </div>
                        </button>

                        <Modal
                            show={this.state.greenModal}
                            onHide={this.greenHide}
                        >
                            <Modal.Header className="btn-success">
                                <Modal.Title>Nezáujem</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <Row>
                                        <Col md={12}>
                                            <Select
                                                label={"Čiselník dôvodov"}
                                                options={this.state.reasons}
                                                value={this.tmpReasonSelected}
                                                onChange={this.changeSelected.bind(this)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl
                                                id="reason"
                                                componentClass="textarea"
                                                value={this.state.note}
                                                placeholder="Poznámka (povinné)"
                                                onChange={this.setNote}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.greenCancel}>Zrušiť</Button>
                                <Button className="btn-success" onClick={this.greenHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                </div>
            </div>
        );
    }
}