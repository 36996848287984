import React, {Component} from 'react';

import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';

export default class IconTooltip extends Component {
    render(){
        return (
            <OverlayTrigger
                overlay={<Tooltip id={this.props.id}>{this.props.tooltip}</Tooltip>}
                placement="top"
                delayShow={300}
                delayHide={150}
            >
                <div><i className={this.props.classes}/>{this.props.data}</div>
            </OverlayTrigger>
        );
    }
}