import React, {Component} from 'react';
import axios from "axios";

export default class ResponseButtons extends Component {

    hangHandler = () => {
                this.props.setData("status", 0);
                this.props.hang();

    };

    respondHandler = () => {
        this.props.respond();
    };

    render(){
        return(
            <div>
                <div className="row main-btns">
                    <div className="col-md-6">
                        <button type="button" className="btn btn-warning btn-xlg" onClick={this.hangHandler.bind(this)}>
                            <i className="icon-phone-hang-up position-left"/>Nezdvihol/Položiť
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className="btn btn-success btn-xlg" onClick={this.respondHandler.bind(this)}>
                            <i className="icon-phone-wave position-left"/>Zdvihol
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}