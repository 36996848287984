import React, {Component} from 'react';
import {Button, Modal} from "react-bootstrap";

export default class GDPRButtons extends Component {
    constructor(){
        super();
        this.state = {
            badNumberModal: false,
            disagreeModal: false
        }
    }


    agreeHandler = (years) => {
        this.props.setData("gdpr", years);
        this.props.agree();
    };

    disagreeHandler = () => {
        this.props.setData("status", 2);
        this.props.setData("gdpr", -1);
        this.closeDisagree();
        this.props.disagree();
    };


    wrongNumberHandler = () => {
        this.props.setData("status", -1);
        this.closeBadNumber();
        this.props.disagree();
    };

    closeDisagree = () => {
        this.setState({disagreeModal: false});
    };

    closeBadNumber = () => {
        this.setState({badNumberModal: false});
    };

    openDisagree = () => {
        this.setState({disagreeModal: true});
    };

    openBadNumber = () => {
        this.setState({badNumberModal: true});
    };

    render() {
        return (
            <div>
                <div className="row main-btns">
                    <div className="col-md-3">
                        <button type="button" className="btn bg-success-700 btn-xlg" onClick={this.agreeHandler.bind(this, 5)}>
                            <div>
                                <div>
                                    <i className=" icon-checkmark4 position-left"/>Súhlas
                                </div>
                                <div className="text-size-xlarge">5 rokov</div>
                            </div>
                        </button>
                    </div>
                    <div className="col-md-3">
                        {/*<button type="button" className="btn bg-success btn-xlg" onClick={this.agreeHandler.bind(this, 3)}>*/}
                        <button type="button" className="btn bg-success btn-xlg" onClick={this.props.agree}>
                            <div>
                                <div>
                                    Preskoč
                                </div>
                                <div className="text-size-xlarge">GDPR</div>
                            </div>
                        </button>
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-warning btn-xlg" onClick={this.openDisagree.bind(this)}>
                            Nesúhlas
                        </button>

                        <Modal show={this.state.disagreeModal} onHide={this.closeDisagree.bind(this)}
                               bsSize={"small"}>
                            <Modal.Header className={"btn-warning"}>
                                <Modal.Title>Skutočne zákazník nesúhlasí s GDPR? Hovor bude ukončený.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Button className={"btn-warning"}
                                        onClick={this.disagreeHandler.bind(this)}>Áno</Button>
                                <Button onClick={this.closeDisagree.bind(this)}>Nie</Button>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn bg-yellow btn-xlg"  onClick={this.openBadNumber.bind(this)}>
                            <div>
                                <div>
                                    Zlé číslo
                                </div>
                            </div>
                        </button>

                        <Modal show={this.state.badNumberModal} onHide={this.closeBadNumber.bind(this)}
                               bsSize={"small"}>
                            <Modal.Header className={"bg-yellow"}>
                                <Modal.Title>Skutočne bolo vytočené zlé číslo? Hovor bude ukončený.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Button className={"bg-yellow"}
                                        onClick={this.wrongNumberHandler.bind(this)}>Áno</Button>
                                <Button onClick={this.closeBadNumber.bind(this)}>Nie</Button>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}