import React, {Component} from 'react';
import 'moment/locale/sk';
import Switch from "react-switch";

export default class App extends Component {
    constructor() {
        super();
        this.state = {
            checked: "true" === window.localStorage.getItem('homeoffice'),
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(checked) {
        window.localStorage.setItem('homeoffice', checked)
        this.setState({checked});
    }
    render(){
        return(
            <div>
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="col-md-4">
                            <span>Home Office</span>
                            <Switch
                                onChange={this.handleChange}
                                checked={this.state.checked}
                                height={15}
                                width={35}
                            />
                    </div>
                    <div className="col-md-5">
                        <span>{window.localStorage.getItem("name")}&nbsp;</span>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-danger btn-nav" onClick={() => window.dispatchEvent(new Event('logout'))}>Odhlásiť</button>
                    </div>
                </div>
            </div>


        );
    }
}
