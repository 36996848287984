import React, {Component} from 'react';
import "./SimpleTable.css"

import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;


/**
 * @name SimpleTable
 * @param content
 */

export default class SimpleTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: props.content.body && props.content.head ? props.content : false,
            dataTable: props.dataTable||false
        }
    }

    handleRowClick = (row) =>{
        if(this.props.rowClick){
            this.props.rowClick(row);
        }
    };


    componentWillReceiveProps(props) {
        this.setState({content: props.content, dataTable: props.dataTable||false}, );
    }

    render() {

        if (this.state.content === false) {
            return (<div></div>);
        }
        try {
            return (
                <div className="table-responsive">
                    <table  ref={table => this.dataTable = table} className="table table-framed table-striped table-hover">
                        <thead>
                        <tr>
                            {
                                this.props.content.head.map(content => (
                                    <th key={content.value}> {content.value} </th>
                                ))
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.content.body.map((content, rowIndex) => (
                                <tr key={rowIndex} style={content.css ? content.css : {}} onClick={this.handleRowClick.bind(this, content.rowid)}>
                                    {content.columns.map((contents, colIndex) => (
                                        <td key={colIndex} rowSpan={contents.rowspan} colSpan={contents.colspan}>
                                            {
                                                    <div style={contents.css ?? {}}>
                                                        {contents.value.toString().split('\n').map((item, index) => (
                                                            <span  key={item + "" + index}>{item}<br/></span>
                                                        ))}
                                                    </div>
                                            }
                                        </td>
                                    ))}
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            );
        } catch (e){
            console.trace(e);
            console.log(this.props.content);
            return(<div></div>);
        }

    }
}
