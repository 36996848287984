import React, {Component} from 'react';
import {Button, FormControl, Modal, Nav, NavItem} from "react-bootstrap";
import axios from 'axios';
import Select from "./Select";

export default class Mailer extends Component {

    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            activeTab: 1,
            sms: {},
            mail: {},
            body: '',
            loaded: false,
            subject: ''
        }
    }

    componentDidMount() {
        axios.get(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token') + "&action=MESSAGES&contact_id=" + this.props.person.person_id)
            .then(response => {
                let {sms, mail} = response.data;
                let activeTab = 1;
                if(sms.length === 0){
                    activeTab = 2;
                }
                this.setState({
                    sms: sms,
                    mail: mail,
                    loaded: true,
                    activeTab: activeTab
                })
            }).catch(error => {
                console.trace(error);
        });
    }

    toggleModal = () => {
        this.setState({showModal: !this.state.showModal});
    };

    messageHandler = () => {
        let {body, subject, activeTab} = this.state;
        let data = {
            contact_id: String(this.props.person.person_id),
            text: body,
            message_type: String(activeTab),
            subject: subject
        };
        axios.post(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token') + "&action=send_message", data)
            .then(response => console.log(response))
            .catch(error => console.trace(error));
        this.toggleModal();
    };

    handleSelectMessage = (selectedKey) => {
        let options;
        if(selectedKey === 1){
            options = this.state.sms;
        } else {
            options = this.state.mail;
        }
        this.setState({activeTab: selectedKey, body:  options[0].text});
    };

    changeText = (index) => {
        let options;
        if(this.state.activeTab === 1){
            options = this.state.sms;
        } else {
            options = this.state.mail;
        }
        this.setState({
            body: options[index].text,
            subject: options[index].value
        });
    };

    setNote = (event) => {
        this.setState({body: event.target.value});
    };

    render() {
        let {person} = this.props;
        let {showModal, activeTab, sms, mail, loaded} = this.state;
        let {firstname, surname, email} = person;
        let options;
        if(this.state.activeTab === 1){
            options = sms;
        } else {
            options = mail;
        }
        if(!loaded || sms.length + mail.length === 0 || window.localStorage.getItem("messages") === 'false'){
            return null;
        }
        return (
            <span>
                <div className="heading-elements">
                    <button type="button" className="btn btn-primary heading-btn"
                            onClick={this.toggleModal.bind(this)}>Poslať správu
                    </button>
                </div>
                <Modal show={showModal}
                       onHide={this.toggleModal.bind(this)}
                       bsSize={"lg"}>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>Správa pre {firstname} {surname}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Nav bsStyle={"tabs"} activeKey={activeTab}
                                     onSelect={this.handleSelectMessage.bind(this)} justified>
                                    {sms.length > 0 ? (
                                        <NavItem eventKey={1}>
                                            <b>SMS</b>
                                        </NavItem>
                                    ) : null}
                                    {String(email).includes('@') && mail.length > 0 ?
                                        (<NavItem eventKey={2}>
                                            <b>E-Mail</b>
                                        </NavItem>) : null}

                                </Nav>
                                <div>
                                    <Select options={options} onChange={this.changeText} value={options[0].id}/>
                                    <FormControl
                                        {...(this.state.body ? {value: this.state.body} : {})}
                                        id="messageText"
                                        style={{minHeight: "200px"}}
                                        componentClass="textarea"
                                        placeholder="Text správy"
                                        onChange={this.setNote}
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className={"btn-primary"}
                                        onClick={this.messageHandler.bind(this)}>Odoslať</Button>
                                <Button onClick={this.toggleModal.bind(this)}>Zrušiť</Button>
                            </Modal.Footer>
                </Modal>
            </span>
        );
    }
}