import React, {Component} from 'react';

//import $ from 'jquery';
import Select2 from "react-select";

// window.jQuery = $;
// window.$ = $;
// global.jQuery = $;
// eslint-disable-next-line
//const select2 = require('select2/dist/js/select2.full.js');

/**
 * @name Select
 * @param {string} label <a target='blank' href='http://localhost:3000/json/select/options.json'>/json/select/options.json'</a>
 * @param {string} placeholder
 * @param {string} size (xs/sm/lg)
 * @param {boolean} checked
 * @param {boolean} disabled
 * @param {boolean} inline
 * @param {boolean} showDefault - zobrazuje prazdnu hodnotu ako prvu moznost
 */

export default class Select extends Component {
    constructor(props) {
        super(props);
        let size = "";

        if (props.size === "xs") {
            size = "select-xs";
        } else if (props.size === "sm") {
            size = "select-sm";
        } else if (props.size === "lg") {
            size = "select-lg";
        }
        let options = props.options.map(option => (
            {value: option.id, label: option.value}
        ))
        this.state = {
            size: size,
            value: props.value || options[0],
            options: options
        };
    }

    componentWillReceiveProps(props) {
        let options = props.options.map(option => (
            {value: option.id, label: option.value}
        ))
        this.setState({value: props.value, options: options}, () => {this.initSelect();});
    }

    componentDidMount() {
        this.initSelect();
        // console.log("selected " + this.state.value);
        this.props.onChange(this.state.value.value || this.state.value);
    }

    // iconFormat = (icon) => {
    //     if (!icon.id) {
    //         return icon.text;
    //     }
    //
    //     let $icon = "<i class='icon-" + $(icon.element).data('icon') + "'></i>" + icon.text;
    //
    //     return $icon;
    // };

    initSelect = () => {
        // console.log('select2 init');
        //
        // try {
        //     let el = $(this.selectInput);
        //     el.select2({
        //         width: '100%',
        //         placeholder: this.props.placeholder,
        //         containerCssClass: this.state.size,
        //         minimumResultsForSearch: Infinity,
        //         templateResult: this.iconFormat,
        //         templateSelection: this.iconFormat,
        //         escapeMarkup: function (m) {
        //             return m;
        //         }
        //     });
        //     el.on("change", this.handleChange.bind(this));
        // } catch (e) {
        //     console.trace(e);
        // }
    };

    handleChange(value, event) {
        if(event.action === "select-option"){
            this.setState({
                value: value.value
            }, () => {
                this.callApi();
            });
        }
    }

    callApi() {
        this.props.onChange(this.state.value);
    }

    render() {
        let options = this.state.options;
        // else if (this.props.groupedOptions) {
        //     options = this.props.groupedOptions.map(group => (
        //         <optgroup label={group.value}>
        //             {group.options.map(option => (
        //                 <option data-icon={option.icon} value={option.id} key={option.id}>{option.value}</option>
        //             ))}
        //         </optgroup>
        //     ))
        // }
        return (
            <div className="form-group">
                <label>{this.props.label}</label>
                {/*<select ref={input => this.selectInput = input} defaultValue={this.state.value}*/}
                {/*        className="select-search btn-success">*/}
                {/*    {this.props.showDefault ?(*/}
                {/*        <option value={-1}>---</option>*/}
                {/*    ) : ""}*/}
                {/*    {options}*/}
                {/*</select>*/}
                <Select2 isClearable={false} defaultValue={options.find(value => value.value === this.state.value) || options[0]} onChange={this.handleChange.bind(this)} options={options}/>
            </div>
        );
    }
}






