import React, {Component} from "react";
import Switch from "react-switch";
import './Switch.css';

export default class Switcher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked,
            position: props.position ? props.position : "left"
        };
        this.onChange = this.props.onChange ? this.props.onChange.bind(this) : null;
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        if(this.props.onChange) {
            this.props.onChange(this.props.id, checked);
        }
        this.setState({checked});
    }

    render() {
        return (
            <div className="form-group">
                <div className="row">
                    {this.state.position === 'left' ?
                        (<div className="col-sm-10">
                            <label htmlFor={this.props.id}>
                                <span>{this.props.label}</span>
                            </label>
                        </div>) : ""}

                    <div className="col-sm-2">
                        <Switch
                            onChange={this.handleChange}
                            checked={this.state.checked}
                            id={this.props.id}
                        />
                    </div>
                    {this.state.position === 'right' ?
                        (<div className="col-sm-10">
                            <label htmlFor={this.props.id}>
                                <span>{this.props.label}</span>
                            </label>
                        </div>) : ""}
                </div>
            </div>
        );
    }
}
