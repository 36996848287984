import React, {Component} from 'react';
import {Button, Modal} from "react-bootstrap";

export default class AnswerButtons extends Component {
    constructor(){
        super();
        this.state = {
            hangModal: false
        }
    }

    promptHang = () => {
        this.setState({hangModal: true});
    };

    closeHangPrompt = () => {
        this.setState({hangModal: false});
    };

    hangHandler = () => {
        //window.socket3cx.send("{\"function\":\"DropCall\",\"Parameters\": {\"Extension\":\"" + window.localStorage.getItem("extension") + "\"},\"token\":\"" + window.localStorage.getItem("token_3cx") + "\" }");
        this.props.hang();
    };

    render(){
        return(
            <div>
                <div className="row main-btns">
                    <div className="col-md-6">
                        <button type="button" className="btn btn-warning btn-xlg" onClick={this.promptHang}>
                            <i className="icon-phone-hang-up position-left"/>Zrušiť
                        </button>

                        <Modal show={this.state.hangModal}
                               onHide={this.closeHangPrompt.bind(this)}
                               bsSize={"small"}>
                            <Modal.Header className={"btn-warning"}>
                                <Modal.Title>Prajete si zrušiť hovor?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Button className={"btn-warning"}
                                        onClick={this.hangHandler.bind(this)}>Áno</Button>
                                <Button onClick={this.closeHangPrompt.bind(this)}>Nie</Button>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className="btn btn-success btn-xlg" onClick={this.props.answer}>
                            <i className="icon-phone-wave position-left"/>Zdvihnúť
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}