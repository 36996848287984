import React, {Component} from 'react';
import axios from 'axios';
import Workflow from "./Workflow";
import Detail from "./Detail";
import "./App.css"
import * as moment from 'moment';
import 'moment/locale/sk'
import SimpleTable from "./Components/SimpleTable";
import {Button, Modal, Nav, NavItem} from 'react-bootstrap';
import {Redirect} from "react-router-dom";
import Mailer from "./Components/Mailer";
import PersonNote from "./Components/PersonNote";
import QueueTable from "./Components/QueueTable";

export default class CallFront extends Component {
    constructor(props) {
        super(props);
        moment.locale('sk');
        this.state = {
            personId: 0,
            number: 0,
            data: {},
            isLoaded: false,
            firstCall: true,
            agreed: false,
            buttons: props.number ? -1 : 0,
            error: null,
            items: {},
            stats: {},
            queue: {},
            history: {},
            displays: {},
            datetime: '',
            activeTabPerson: 1,
            activeTabCaller: 2,
            callerNumber: props.number ? props.number : 0,
            redirectBack: false,
            waiting: false,
            hangModal: false,
            support: false,
            isPartialyLoaded: false,
            messageModal: false,
            activeTabMessage: 1,
            call_types: "Nešpecifikovaný typ hovoru",
            createdOrderinNumber: ""
        };
        this.newData = {};
        this.count = 0;
        this.getNewPerson = this.getNewPerson.bind(this);
        // if (!window.socket3cx) {
        //     window.socket3cx = new WebSocket(window.localStorage.getItem('wsurl'));
        //     window.socket3cx.onopen = () => {
        //         console.log('connection opened');
        //     };
        //     window.socket3cx.onmessage = (msg) => console.log(msg);
        //     window.socket3cx.onerror = (err) => console.log(err);
        //     window.socket3cx.onclose = () => {
        //         console.log("connection closed");
        //         window.socket3cx = undefined
        //     };
        // }
    }

    handleSelectPerson = (selectedKey) => {
        this.setState({activeTabPerson: selectedKey});
    };

    handleSelectCaller = (selectedKey) => {
        this.setState({activeTabCaller: selectedKey});
    };

    componentDidMount() {
        this.getNewPerson();
    }

    //<editor-fold desc="data handle">
    getNewPerson = () => {

        if (this.state.isLoaded) {
            this.submitData();
        }

        this.newData = {};

        if (this.state.callerNumber !== 0) {
            axios.get(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token') + "&mobile=" + this.state.callerNumber.replace('+421', '0')).then(
                res => {
                    const data = res.data;
                    this.loadData(data);
                    this.toGroup(0); //FIXME - prepnut na 0 pri konci vyvoja
                    this.count++;
                    this.setState({buttons: 2});   //TODO - ked budeme mat moznost zodvihnut hovor z appky, treba nastavit na -1
                }
            ).catch(error => {
                    console.log(error);
                    this.setState({error: error, isLoaded: false, isPartialyLoaded: false});
                    if (error.response && error.response.status === 401) {
                        this.props.logout(this, "/login");
                    }
                }
            );
        } else if (!this.state.isLoaded) {
            this.setState({waiting:true})
            axios.get(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token')).then(
                res => {
                    const data = res.data;
                    console.log(data)
                    this.loadData(data);
                    this.toGroup(0); //FIXME - prepnut na 0 pri konci vyvoja
                    this.count++;
                    this.setState({waiting:false})
                }
            ).catch(error => {
                    console.log(error);
                    this.setState({error: error, isLoaded: false, isPartialyLoaded: false});
                    if (error.response && error.response.status === 401) {
                        this.props.logout(this, "/login");
                    }
                }
            );
        }

    };


    loadData = (data) => {
        this.setState({
            personId: data.person.person_id,
            queueId: data.person.queue_id,
            data: data,
            isLoaded: true,
            firstCall: data.person.firstCall,
            agreed: data.person.agreement,
            number: data.person.mobile,
            datetime: data.person.datetime,
            error: null,
            items: data.items,
            history: data.history,
            displays: data.displays,
            stats: data.stats,
            queue: data.queue,
            support: data.person.support,
            activeTabMessage: 1,
            isPartialyLoaded: false,
            personNote: data.person.note,
            call_types: data.person.call_types === '' ? "Nešpecifikovaný typ hovoru" : data.person.call_types,
            createdOrderinNumber : data.orderinNumber ? data.orderinNumber : ""
        }, () => {
            this.setData('contact_id', this.state.personId);
            this.setData('queue_id', this.state.queueId);
        });
    };

    changePersonData = (person) => {
        let data = this.state.data;
        data.person = person;
        this.setState({data: data});
    };

    setData = (key, value) => {
        this.newData[key] = value;
    };

    submitData = () => {
        if (this.state.callerNumber !== 0) {
            this.setState({redirectBack: true});
        }

        axios.post(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem('token'), this.newData)
            .then(res => {
                const data = res.data;
                this.loadData(data);
                if(data.orderinNumber && data.orderinNumber !== "") {
                    this.openCreatedOrderModal()
                }
                this.toGroup(0);
                this.count++;
            })
            .catch(err => {
                console.log(err);
                this.setState({error: err, isLoaded: false, isPartialyLoaded: true});
                if (err.response && err.response.status === 401) {
                    this.props.logout(this, "/login");
                }
            });


    };
    //</editor-fold>

    //<editor-fold desc="workflow button changing">
    back = () => {
        if (this.state.buttons > 0) {
            if (this.state.buttons === 5) {
                this.setState({buttons: 3});
            } else if (this.state.buttons === 6) {
                this.setState({buttons: 2});
            } else {
                this.setState({buttons: this.state.buttons - 1});
            }
        }
    };

    toGroup = (group) => {
        this.setState({buttons: group});
    };

    nextCall = () => {
        this.getNewPerson();
    };

    //</editor-fold>

    promptHang = () => {
        this.setState({hangModal: true}, () => {

        });
    };

    closeHangPrompt = () => {
        this.setState({hangModal: false});
    };

    openCreatedOrderModal = () => {
        this.setState({createdOrderModal: true});
    };

    closeCreatedOrderModal = () => {
        this.setState({createdOrderModal: false});
    };

    hangHandler = () => {
        let data= {
            cxToken: window.localStorage.getItem('token_3cx'),
            extension: window.localStorage.getItem("extension"),
            homeoffice: window.localStorage.getItem("homeoffice"),
            hangUpMethod: "buttonCallFront"
        }
        axios.post(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token')
            + "&action=HANGUP",data).then(
            res => {
                this.closeHangPrompt();
            }
        ).catch(error => {
                console.log(error)
            }
        );
    };

    callPerson = (number) => {
        // console.log(number);
        if (this.state.buttons !== 0) {
            return;
        }

        this.setState({isLoaded: false, isPartialyLoaded: true}, () => {
            // console.log(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token') + "&mobile=" + encodeURIComponent(number));
            axios.get(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token') + "&mobile=" + encodeURIComponent(number)).then(
                res => {
                    const data = res.data;
                    console.log(data);
                    this.loadData(data);
                    this.toGroup(0);
                    this.count++;
                }
            ).catch(error => {
                    console.log(error);
                    this.setState({error: error, isLoaded: false, isPartialyLoaded: true});
                    if (error.response && error.response.status === 401) {
                        this.props.logout(this, "/login");
                    }
                }
            );
        });

    };


    render() {
        let contentPerson;
        let contentCaller;

        switch (this.state.activeTabPerson) {
            default:
            case 1:
                contentPerson = this.state.items;
                break;

            case 2:
                contentPerson = this.state.history;
                break;

            case 3:
                contentPerson = this.state.displays;
                break;
        }

        switch (this.state.activeTabCaller) {
            default:
            case 1:
                contentCaller = (<SimpleTable content={this.state.stats}/>);
                break;

            case 2:
                contentCaller = (<QueueTable content={this.state.queue} rowClick={this.callPerson}/>);
                break;

            case 3:
                contentCaller = (<PersonNote id={this.state.personId} note={this.state.personNote}/>);
                break;
        }

        return (
            <div className="row">
                {this.state.redirectBack ? <Redirect to="/"/> : ''}
                <div className="col-md-7">
                    <div className="panel panel-white">
                        <div className="panel-heading">
                            <h3 className="panel-title text-bold">Detail - {this.state.datetime}</h3>
                            {this.state.isLoaded ? (
                                <Mailer person={this.state.data.person}/>
                            ) : null}
                        </div>

                        <div className="panel-body">
                            <Detail data={this.state.data} isLoaded={this.state.isLoaded} error={this.state.error} onEdit={this.changePersonData.bind(this)}/>
                        </div>
                    </div>
                    <div className="panel panel-white">
                        <div className="panel-heading">
                            <h3 className="panel-title text-bold">{this.state.call_types} &nbsp;{this.state.support ? (
                                <span style={{color: "red"}}>&nbsp;Podporný telefonát</span>) : ''}</h3>

                            {this.state.buttons <= 0 ? '' : (
                                <div className="heading-elements">
                                    {(this.state.buttons > 1  && this.state.buttons!==7) &&
                                        <span>
                                            <button type="button" className="btn btn-danger heading-btn"
                                                    onClick={this.promptHang.bind(this)}>Položiť
                                            </button>

                                            <Modal show={this.state.hangModal}
                                                   onHide={this.closeHangPrompt.bind(this)}
                                                   bsSize={"small"}>
                                                <Modal.Header className={"btn-warning"}>
                                                    <Modal.Title>Prajete si zrušiť hovor?</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Button className={"btn-warning"}
                                                            onClick={this.hangHandler.bind(this)}>Áno</Button>
                                                    <Button onClick={this.closeHangPrompt.bind(this)}>Nie</Button>
                                                </Modal.Body>
                                            </Modal>
                                        </span>
                                    }
                                    {((this.state.callerNumber !== 0 && (this.state.buttons === 2)) || this.state.support || this.state.buttons === 7) ? '' : (
                                        <button type="button" className="btn btn-primary heading-btn"
                                                onClick={this.back.bind(this)}>Späť
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="panel-body">
                            <Workflow getNext={this.getNewPerson} firstCall={this.state.firstCall}
                                      support={this.state.support} agreed={this.state.agreed} setData={this.setData}
                                      number={this.state.number} buttons={this.state.buttons}
                                      toGroup={this.toGroup.bind(this)} nextCall={this.nextCall.bind(this)}
                                      isLoaded={this.state.isLoaded} calendar={this.state.isLoaded ? this.state.data.person.calendar : {}}
                            />
                        </div>
                    </div>

                </div>
                <div className="col-md-5" style={{height: '100%'}}>

                    <div className="panel panel-white">
                        <div className="panel-body">
                            <Nav bsStyle={"tabs"} activeKey={this.state.activeTabPerson}
                                 onSelect={this.handleSelectPerson.bind(this)} justified>
                                <NavItem eventKey={1}>
                                    <b>Položky</b>
                                </NavItem>
                                <NavItem eventKey={2}>
                                    <b>História hovorov</b>
                                </NavItem>
                                <NavItem eventKey={3}>
                                    <b>Ukážky</b>
                                </NavItem>
                            </Nav>
                            <div style={{"maxHeight": "340px", "overflow": "auto"}}>
                                {this.state.isLoaded ? (<SimpleTable content={contentPerson}/>) : ''}
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-white">
                        <div className="panel-body">
                            <Nav bsStyle={"tabs"} activeKey={this.state.activeTabCaller}
                                 onSelect={this.handleSelectCaller.bind(this)} justified>
                                <NavItem eventKey={1}>
                                    <b>Štatistika</b>
                                </NavItem>
                                <NavItem eventKey={2}>
                                    <b>Fronta</b>
                                </NavItem>
                                <NavItem eventKey={3}>
                                    <b>Poznámka</b>
                                </NavItem>
                            </Nav>
                            <div style={{"maxHeight": "500px", "overflow": "auto"}}>
                                {this.state.isLoaded || this.state.isPartialyLoaded ? (contentCaller) : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.createdOrderModal}   onHide={this.closeCreatedOrderModal.bind(this)} bsSize={"small"}>
                    <Modal.Header className={"btn-success"}>
                        <Modal.Title>Objednávka bola vytvorená</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Číslo objednávky: {this.state.createdOrderinNumber}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeCreatedOrderModal.bind(this)}>Zatvoriť</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}
