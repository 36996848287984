import React, {Component} from 'react';
import {
    Modal,
    FormControl,
    Button
} from 'react-bootstrap';


export default class SupportButtons extends Component {
    constructor() {
        super();
        this.state = {
            noteModal: false
        }
        this.note = ''
    }


    clickHandler = () => {
        this.setState({noteModal: true})
    };

    submitNote = () => {
        this.props.setData("status", 5);
        this.props.setData("note", this.note);
        this.hideModal();
        this.props.next();
    };

    hideModal = () => {
        this.setState({noteModal: false})
    };

    setNote = (event) => {
        this.note = event.target.value;
    };

    render() {
        return (
            <div>
                <div className="row main-btns">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-primary btn-xlg"
                                onClick={this.clickHandler.bind(this)}>
                            <i className="icon-phone-hang-up position-left"/>Hovor ukončený
                        </button>
                        <Modal
                            show={this.state.noteModal}
                            onHide={this.hideModal}
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>Poznámka</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormControl
                                    id="note"
                                    componentClass="textarea"
                                    placeholder="Zadajte poznámku k hovoru"
                                    onChange={this.setNote}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.hideModal}>Zrušiť</Button>
                                <Button className="bg-primary" onClick={this.submitNote.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}