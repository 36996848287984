import React, {Component} from 'react';
import "./App.css";
import axios from 'axios';
import * as moment from 'moment';
import 'moment/locale/sk';
import CallFront from "./CallFront";
import Login from "./Login";
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router, Redirect, Switch} from "react-router-dom";
import Logout from "./Logout";

export default class App extends Component {
    constructor() {
        super();
        moment.locale('sk');
        this.storage = window.localStorage;

        //TOTO TREBA PRED BUILDOVANIM NASTAVI Z DEBUG IP NA OSTRE

        this.initStorage();

        /* nettrade api server */
        //window.localStorage.setItem("wsurl", "ws://217.75.92.209:8033");

        this.state = {
            logged: this.storage.getItem('logged') ? this.storage.getItem('logged') === 'true' : false,
            token: null,
            intrauser: 0,
            token_3cx: null,
            extension: null,
            number1: '',
            number2: '',
            number3: '',
            response: 200,
            logout: false,
            aktiv: false,
            name: ''
        };
        window.addEventListener('logout', () => this.logout(this, "/"));
        this.renderLogout();
    }

    logout = (otherThis,from) => {
        this.setState({
            logged: false,
            token: null,
            intrauser: 0,
            response: 200
        }, () => {
            try {
                otherThis.props.history.push(from);
            } catch(error){
                this.setState({logout:true});
            }
            this.renderLogout();
            try{
                //window.socket3cx.close();
            } catch (e) {
                console.log(e);
            }
        });
        this.storage.clear();
        this.initStorage();
    };

    initStorage = () => {
        /* DEV */
         // window.localStorage.setItem("url", "http://192.168.1.18:8080/offeris");        //dominik
         // window.localStorage.setItem("url", "https://lences.offeris.com/offeris");
        //window.localStorage.setItem("url", "/offeris");        //robo
        // window.localStorage.setItem("wsurl", "ws://localhost:9999");

        /* PRODUCTION */
        window.localStorage.setItem("url", "https://zaren.offeris.com/zaren");
        // window.localStorage.setItem("wsurl", "wss://185.142.50.11:8033");
    };

    login = (otherThis, from, name, password) => {
        axios.post(window.localStorage.getItem('url') + '/CCLoginServlet', {loginName: name, loginPassword: password})
            .then(res => {
                    const data = res.data;
                this.setState({
                        logged: true,
                        token: data.token,
                        intrauser: data.intrauser_id,
                        token_3cx: data.token_3cx,
                        extension: data.extension,
                        number1: data.number1,
                        number2: data.number2,
                        number3: data.number3,
                        aktiv: data.aktiv,
                        name: data.name,
                        response: 200,
                        messages: String(data.messages)
                    }, () => {
                        this.storage.setItem('logged', 'true');
                        this.storage.setItem('token', this.state.token.toString());
                        this.storage.setItem('token_3cx', this.state.token_3cx.toString());
                        this.storage.setItem('extension', this.state.extension.toString());
                        this.storage.setItem('number1', this.state.number1.toString());
                        this.storage.setItem('number2', this.state.number2.toString());
                        this.storage.setItem('number3', this.state.number3.toString());
                        this.storage.setItem('aktiv', this.state.aktiv.toString());
                        this.storage.setItem('messages', this.state.messages);
                        this.storage.setItem('name', this.state.name);
                        otherThis.props.history.push(from);
                        this.renderLogout();


                        window.conOpened = false;

                        // window.socket3cx = new WebSocket(window.localStorage.getItem('wsurl'));
                        // window.socket3cx.onopen = () => {console.log('connection opened'); window.conOpened = true;};
                        // window.socket3cx.onmessage = (msg) => console.log(msg);
                        // window.socket3cx.onerror = (err) => console.log(err);
                        // window.socket3cx.onclose = () => {console.log("connection closed"); window.conOpened = false};
                    });
                }
            ).catch(error => {
                let err_message = error;
                if(error.response){
                    if(error.response.status){
                        err_message = error.response.status;
                    } else {
                        err_message = error.response;
                    }
                }
                console.log(err_message);
            this.setState({
                logged: false,
                token: null,
                response: err_message,
                intrauser: 0
            },() => {
                this.renderLogout();
            });
        });
    };

    renderLogout = () => {
        if(this.state.logged){
            ReactDOM.render(<Logout />, document.getElementById('logout'));
        } else {
            ReactDOM.render('', document.getElementById('logout'));
        }
    };

    /*
     * parametre posielane 3CX
     * %CallerNumber%
     * %CallerDisplayName%
     */

    render() {

        return (
            <Router>
                <Switch>
                    <Route exact path="/" render={props =>
                        this.state.logged ? (
                            <CallFront {...props} logout={this.logout.bind(this)}/>
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: {from: props.location}
                                }}
                            />
                        )
                    }/>
                    <Route path="/call/:something/:number" render={props =>

                        this.state.logged ? (<CallFront {...props} number={props.match.params.number} logout={this.logout.bind(this)}/>) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: {from: props.location}
                                }}
                            />
                        )
                    }/>
                    <Route path="/call/:number" render={props =>

                        this.state.logged ? (<CallFront {...props} number={props.match.params.number} logout={this.logout.bind(this)}/>) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: {from: props.location}
                                }}
                            />
                        )
                    }/>
                    <Route path="/login" render={(props) => <Login response={this.state.response} logged={this.state.logged} {...props} login={this.login.bind(this)}/>}/>
                </Switch>
            </Router>
        )
    }
}
