import React, {Component} from 'react';
import {Alert} from "react-bootstrap";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            logged: props.logged,
            name: '',
            password: '',
            response: props.response ? props.response : 200
        }
    }

    login = () => {
        const {from} = this.props.location.state || {from: {pathname: "/"}};
        this.setState({redirect: true}, () => {
            this.props.login(this, from, this.state.name, this.state.password);
        });
    };

    handleName = (e) => {
        this.setState({name: e.target.value});
    };

    handlePassword = (e) => {
        this.setState({password: e.target.value});
    };

    componentWillReceiveProps(props) {
        this.setState({response: props.response});
    }

    handleEnter = (event) => {
        if(event.key === 'Enter'){
            this.login();
        }
    };

    render() {

        return (
            <div className="panel panel-body login-form"
                 style={{"width": "320px", "margin": "50px auto 20px auto", "position": "relative"}}>
                <div className="text-center">
                    <div style={{"marginBottom": "50px"}}><img alt={"logo"} style={{"maxWidth": "100%"}}
                                                               src="https://zaren.sk/wp-content/uploads/2022/03/Zaren-logo-SVG.svg"/>
                    </div>
                </div>

                <div className="form-group has-feedback has-feedback-left">
                    <input type="text" name="loginName" id="loginName" className="form-control" placeholder="Login"
                           value={this.state.name} onChange={this.handleName} onKeyPress={this.handleEnter}/>
                    <div className="form-control-feedback">
                        <i className="icon-user text-muted"/>
                    </div>
                </div>

                <div className="form-group has-feedback has-feedback-left">
                    <input type="password" name="loginPassword" id="loginPassword" className="form-control"
                           placeholder="Heslo"
                           value={this.state.password}
                           onChange={this.handlePassword} onKeyPress={this.handleEnter}/>
                    <div className="form-control-feedback">
                        <i className="icon-lock2 text-muted"/>
                    </div>
                </div>

                <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-block"
                            onClick={this.login.bind(this)}>Prihlásiť <i
                        className="icon-circle-right2 position-right"/></button>
                </div>

                <div className="text-center">
                    {this.state.response === 401 ? (
                        <Alert bsStyle="danger">
                            Zlé meno alebo heslo!
                        </Alert>
                    ) : ''}
                </div>
            </div>
        );
    }
}